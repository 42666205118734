import React from 'react'
import {graphql, StaticQuery} from 'gatsby'
import {STRINGS} from "../../constants/strings";
import AdHeader from "../ads/adHeader";
import {URL} from "../../constants/urls";
import {Header} from '@cg-squad/ui-components';
import {FOLLOW_US} from "../../constants/followUs";

const HeaderWrapper = props => {

  
    const logoSizes = {
      menuLogoWidth: 300,
      menuLogoHeight: 40,
      headerLogoWidth: 300,
      headerLogoHeight: 40
    }

    const renderNavItems = (className) => (
        <StaticQuery
            query={graphql`
          query HeaderCategoryQuery {
            allCategories: allDatoCmsCategory(
                filter: {root: {eq: true}, website: {elemMatch: {name: {eq: "pt"}}}}
                sort: {order: ASC, fields: position}
              ) {
                nodes {
                  slug
                  title
                  children: treeChildren {
                    slug
                    title
                    position
                  }
                }
              }
            }
        `}
            render={data =>
                <Header path={props.path}
                        categoryPath={props.categoryPath}
                        subCategoryPath={props.subCategoryPath}
                        onSubscriptionPopupStateChange={props.onSubscriptionPopupStateChange}
                        followUs={FOLLOW_US}
                        AdHeader={AdHeader}
                        allCategories={data.allCategories} stringsConst={STRINGS} urlsConst={URL}
                        logoSizes={logoSizes}/>

            }
        />
    )

    return (
        <>
          {renderNavItems()}
        </>
    )
}

export default HeaderWrapper
