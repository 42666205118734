import React from 'react'
import {graphql, StaticQuery} from "gatsby";
import {URL} from "../../constants/urls";
import {STRINGS} from "../../constants/strings";
import { Footer } from '@cg-squad/ui-components';
import {FOLLOW_US} from "../../constants/followUs";

const renderNavItems = (isMobile = false, props) => (
    <StaticQuery
        query={graphql`
            query FooterCategoryQuery {
              allCategories: allDatoCmsCategory(
                filter: {root: {eq: true}, slug: {ne: "news"}, website: {elemMatch: {name: {eq: "pt"}}}}
                sort: {order: ASC, fields: position}
              ) {
                nodes {
                  slug
                  title
                }
              }
            }
        `}
        render={data => 
            <Footer categoryPath={props.categoryPath}
                    subCategoryPath={props.subCategoryPath}
                    onSubscriptionPopupStateChange={props.onSubscriptionPopupStateChange} 
                    allCategories={data.allCategories} 
                    stringsConst={STRINGS} urlsConst={URL} 
                    followUs={FOLLOW_US}/>
        }
    />
)


const FooterWrapper = (props) => {
    
    return (
        <>
            {renderNavItems(false, props)}
        </>
    )
}

export default FooterWrapper
